<template>
    <div class="internal-sections add-style-blocks-mi">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Xodimlar</div>
                        <div class="filter-internal-block uchlik">
                            <el-input 
                                class="sorce"
                                prefix-icon="el-icon-search"
                                v-model="filterForm.search"
                                placeholder="Qidiruv"
                                size="small"
                                clearable
                            ></el-input>

                            <el-select
                                v-model="filterForm.clinic_id"
                                filterable
                                clearable
                                remote
                                :placeholder="$t('message.clinics')"
                                :remote-method="remoteMethod"
                                @change="clearData"
                                :loading="loading"
                                size="small">
                                <el-option
                                v-for="item in clinics"
                                :key="item.id"
                                :label="item.legal_entity_name"
                                :value="item.id">
                                </el-option>
                            </el-select>

                            <select-employee-type
                                :id="columns.types_of_employee_id"
                                v-model="filterForm.types_of_employee_id"
                                :size="'small'"
                            >
                            </select-employee-type>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="filter_table" style="padding-right: 130px; padding-top: 5px;">
                    
                    <crm-create-and-column-settings
                        @c-create="drawerCreate = true"
                        :columns="columns"
                        @c-change="updateColumn"
                    >
                    </crm-create-and-column-settings>
                    <!-- <router-link :to="{ name: 'doctorAdd' }">
                        <el-button>Xodimlar</el-button>
                    </router-link>
                    <el-dropdown class="setting-cheek ml-2">
                        <el-button
                            size="small"
                            icon="el-icon-setting"
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(column, index) in columns"
                                :key="index"
                            >
                                <el-checkbox
                                    :checked="column.show"
                                    @change="column.show = !column.show"
                                    >{{ column.title }}</el-checkbox
                                >
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item>Xodimlar </el-breadcrumb-item>
                <el-breadcrumb-item> Tibbiy xodimlar </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-tabs type="border-card" class="my-doctor-tabs mmi2">
            <el-tab-pane>
                <span slot="label" label="Config">
                    <i class="el-icon-c-scale-to-original"></i>
                </span>
                <el-row :gutter="20" v-loading="loadingData">
                    <el-col v-for="(item, index) in filteredDoctors"
                            :key="index"  :xs="12" :sm="12" :md="8" :lg="8" :xl="6">
                         <router-link :to="{ name: 'doctorCardInfo', params: {id: item.id} }"
                            > <div  class="card item-doctor new-item-doctor">
                                <div class="item-doctor-img">
                                    <img
                                        v-if="item.image['id']"
                                        class="img-avatar"
                                        :src="url + '/' + item.image.path"
                                    />
                                    <img
                                        v-else
                                        class="img-avatar"
                                        src="./../../../public/img/avatar_doctor.png"
                                    />
                                </div>
                                <span class="job">
                                    <p class="leff"><i class="el-icon-user"></i> I.F.O:</p>
                                    <p> 
                                        {{
                                        item.name ? item.name : ' ' 
                                        }}
                                        {{
                                            item.surname ? item.surname  : ' '
                                        }}
                                        {{
                                            item.lastname ? item.lastname : ' '
                                        }}
                                    
                                    </p>
                                </span>
                                <span class="job">
                                    <p  class="leff"><i class="el-icon-office-building"></i> Muassasalar:</p>
                                    <p>  {{
                                            item.clinic
                                                ? item.clinic.legal_entity_name
                                                : ""
                                        }} 
                                    </p>
                                </span>
                                <span class="job">
                                    <p  class="leff"><i class="el-icon-suitcase"></i>Mutaxassislik:</p>
                                    <p> {{ item.typesOfEmployee ? item.typesOfEmployee.name : "" }} </p>
                                
                                </span>
                                <span class="job">
                                    <p class="leff"><i class="el-icon-medal"></i>Turi:</p>
                                    <p> {{
                                        item.direction ? item.direction.name : '' 
                                    }} </p>
                                </span>
                            </div>
                        </router-link> 
                        
                    </el-col>
                </el-row>
                <div class="my-pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :pagination="pagination"
                    ></crm-pagination>
                </div>
            </el-tab-pane>

            <el-tab-pane>
                <span slot="label" label="Config">
                    <span slot="label"><i class="el-icon-date"></i></span>
                </span>

                <div class="internal-sections-table">
                    <table
                        class="table-my-code table-bordered"
                        v-loading="loadingData"
                    >
                        <thead>
                            <tr>
                                <th class="w50p" v-if="columns.id.show">
                                    {{ columns.id.title }}
                                </th>

                                <th v-if="columns.name.show">
                                    {{ columns.name.title }}
                                </th>

                                <th v-if="columns.direction.show">
                                    {{ columns.direction.title }}
                                </th>

                                <th v-if="columns.typesOfEmployee.show">
                                    {{ columns.typesOfEmployee.title }}
                                </th>

                                <th v-if="columns.clinic.show">
                                    {{ columns.clinic.title }}
                                </th>

                                <th v-if="columns.filial.show">
                                    {{ columns.filial.title }}
                                </th>

                                 <th v-if="columns.region.show">
                                    {{ columns.region.title }}
                                </th>

                                 <th v-if="columns.city_district.show">
                                    {{ columns.city_district.title }}
                                </th>

                                <th
                                    v-if="
                                        columns.passport_series_and_number.show
                                    "
                                >
                                    {{
                                        columns.passport_series_and_number.title
                                    }}
                                </th>

                                <th v-if="columns.mobile_tel_number.show">
                                    {{ columns.mobile_tel_number.title }}
                                </th>

                                <th v-if="columns.created_at.show">
                                    {{ columns.created_at.title }}
                                </th>

                                <th v-if="columns.updated_at.show">
                                    {{ columns.updated_at.title }}
                                </th>

                                <th v-if="columns.profilLink.show" style="width: 150px">
                                    {{ columns.profilLink.title }}
                                </th>

                                <th v-if="columns.settings.show">
                                    {{ columns.settings.title }}
                                </th>
                            </tr>

                            <tr class="filter_sorche">
                                <th v-if="columns.id.show">
                                    <el-input
                                        clearable
                                        size="mini"
                                        v-model="filterForm.id"
                                        :placeholder="columns.id.title"
                                        class="id_input"
                                    ></el-input>
                                </th>

                                <th v-if="columns.name.show">
                                    <crm-input
                                        :placeholder="columns.name.title"
                                        v-model="filterForm.name"
                                        :size="'mini'"
                                    ></crm-input>
                                </th>
                                
                                <th v-if="columns.direction.show">
                                    <crm-input
                                        :placeholder="columns.direction.title"
                                        v-model="filterForm.direction"
                                        :size="'mini'"
                                    ></crm-input>
                                </th>

                                <th v-if="columns.typesOfEmployee.show">
                                    <select-employee-type
                                        :id="columns.types_of_employee_id"
                                        v-model="filterForm.types_of_employee_id"
                                        :size="'mini'"
                                    >
                                    </select-employee-type>
                                </th>

                                <th v-if="columns.clinic.show">
                                    <el-select
                                        v-model="filterForm.clinic_id"
                                        filterable
                                        clearable
                                        remote
                                        :placeholder="$t('message.clinics')"
                                        :remote-method="remoteMethod"
                                        @change="clearData"
                                        :loading="loading"
                                        size="mini">
                                        <el-option
                                        v-for="item in clinics"
                                        :key="item.id"
                                        :label="item.legal_entity_name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </th>

                                <th v-if="columns.filial.show">
                                    <select-filial
                                        :id="columns.filialc_id"
                                        v-model="columns.filial_id"
                                        :size="'mini'"
                                        :disabled="true"
                                    >
                                    </select-filial>
                                </th>

                                 <th v-if="columns.region.show">
                                   <el-select v-model="filterForm.region_id" @change="selectedRegionId" :placeholder="columns.region.title" filterable clearable size="mini">
                                        <el-option  v-for="(region,index) in regions" :key="'region-'+index"
                                            :label="region.region_name"
                                            :value="region.id"
                                        ></el-option>
                                    </el-select>
                                </th>

                                <th v-if="columns.city_district.show">
                                    <el-select v-model="filterForm.city_district_id" :placeholder="columns.city_district.title" filterable clearable size="mini">
                                        <el-option  v-for="(city,index) in filtered_city_district" :key="'city-'+index"
                                            :label="city.name"
                                            :value="city.id"
                                        ></el-option>
                                    </el-select>
                                </th>

                                <th
                                    v-if="
                                        columns.passport_series_and_number.show
                                    "
                                >
                                    <crm-input
                                        :placeholder="
                                            columns.passport_series_and_number
                                                .title
                                        "
                                        v-model="
                                            filterForm.passport_series_and_number
                                        "
                                        :size="'mini'"
                                    ></crm-input>
                                </th>

                                <th v-if="columns.mobile_tel_number.show">
                                    <crm-input
                                        :placeholder="
                                            columns.mobile_tel_number.title
                                        "
                                        v-model="filterForm.mobile_tel_number"
                                        :size="'mini'"
                                    ></crm-input>
                                </th>

                                <th v-if="columns.created_at.show">
                                    <crm-date-picker
                                        :placeholder="columns.created_at.title"
                                        v-model="filterForm.created_at"
                                        :size="'mini'"
                                    ></crm-date-picker>
                                </th>

                                <th v-if="columns.updated_at.show">
                                    <crm-date-picker
                                        :placeholder="columns.updated_at.title"
                                        v-model="filterForm.updated_at"
                                        :size="'mini'"
                                    ></crm-date-picker>
                                </th>

                                <th class="settinW" v-if="columns.profilLink.show">
                                    <el-input
                                        :placeholder="columns.profilLink.title"
                                        :disabled="true"
                                        :size="'mini'"
                                    >
                                    </el-input>
                                </th>

                                <th
                                    class="settinW"
                                    v-if="columns.settings.show"
                                ></th>
                            </tr>
                        </thead>

                        <transition-group name="flip-list" tag="tbody">
                            <tr
                                v-for="(doctors, index) in list"
                                :key="index"
                                class="cursor-pointer"
                            >
                                <td v-if="columns.id.show">
                                    {{ doctors.id }}
                                </td>

                                <td v-if="columns.name.show">
                                    {{ doctors.name ? doctors.name : '' }}
                                    {{ doctors.surname ? doctors.surname : '' }}
                                    {{ doctors.lastname ? doctors.lastname : '' }}
                                </td>

                                <td v-if="columns.direction.show">
                                    {{
                                        doctors.direction
                                            ? doctors.direction.name
                                            : ""
                                    }}
                                </td>

                                <td v-if="columns.typesOfEmployee.show">
                                    {{
                                        doctors.typesOfEmployee
                                            ? doctors.typesOfEmployee.name
                                            : ""
                                    }}
                                </td>

                                <td v-if="columns.clinic.show">
                                    {{
                                        doctors.clinic
                                            ? doctors.clinic.legal_entity_name
                                            : ""
                                    }}
                                </td>

                                <td v-if="columns.filial.show">
                                    {{
                                        doctors.filial
                                            ? doctors.filial.name
                                            : ""
                                    }}
                                </td>

                                <td v-if="columns.region.show">
                                    {{
                                        doctors.region
                                            ? doctors.region.region_name
                                            : ""
                                    }}
                                </td>

                                <td v-if="columns.city_district.show">
                                    {{
                                        doctors.city_district
                                            ? doctors.city_district.name
                                            : ""
                                    }}
                                </td>

                                <td
                                    v-if="
                                        columns.passport_series_and_number.show
                                    "
                                >
                                    {{ doctors.passport_series_and_number }}
                                </td>

                                <td v-if="columns.mobile_tel_number.show">
                                    {{ doctors.mobile_tel_number }}
                                </td>

                                <td v-if="columns.created_at.show">
                                    {{ doctors.created_at }}
                                </td>

                                <td v-if="columns.updated_at.show">
                                    {{ doctors.updated_at }}
                                </td>

                                <td v-if="columns.profilLink.show">
                                    <el-button
                                        class="style-link-prifil"
                                        type="success"
                                        size="small"
                                        plain
                                        @click="showProfil(doctors)"
                                    >
                                        <i class="el-icon-view"></i>
                                        <span> Batafsil </span>
                                        <i class="el-icon-right"></i>
                                    </el-button>
                                </td>

                                <td
                                    v-if="columns.settings.show"
                                    class="settings-td"
                                >
                                    <crm-settings
                                        :name="$options.name"
                                        :model="doctors"
                                        :permissionShow="'doctorsController@update'"
                                        :permissionDestroy="'doctorsController@destroy'"
                                        :actions="actions"
                                        @edit="edit"
                                        @delete="destroy"
                                    ></crm-settings>
                                </td>
                            </tr>
                        </transition-group>
                    </table>
                    <div class="my-pagination">
                        <crm-pagination
                            @c-change="updatePagination"
                            :pagination="pagination"
                        ></crm-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="false"
                size="100%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                    :status="false"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                :wrapperClosable="false"
                size="100%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    :status="true"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions} from "vuex";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import CrmCreate from './components/crm-create'
import crmUpdate from "./components/crm-update"; 
import SelectRegion from '@/components/filters/inventory/select-region';
import SelectCityDistricts from '@/components/filters/inventory/select-city-districts';
export default {
    name: "doctors",
    mixins: [list],
    components: {crmUpdate, CrmCreate,SelectCityDistricts,SelectRegion },
    data() {
          return {
            url: process.env.VUE_APP_URL_DOCS,
            clinic_name: '',
            loading:false,
            clinics: []
        };
    },
    computed: {
        ...mapGetters({
            list: "doctors/list",
            columns: "doctors/columns",
            pagination: "doctors/pagination",
            filter: "doctors/filter",
            sort: "doctors/sort",
            regions: "region/inventory",
            filtered_city_district: 'region/filtered_city_district',
        }),
        actions: function () {
            return ["edit", "delete"];
        },

        filteredDoctors: function () {
            let doctors = [];
            let logo = {};
            if (!_.isEmpty(this.list)) {
                this.list.forEach((item, index) => {
                    if (!_.isEmpty(item.images)) {
                        logo['id'] = item.images[0].id;
                        logo['path'] = item.images[0].path;
                    }else{
                        logo['id'] = null;
                        logo['path'] = null; 
                    }

                    doctors.push({
                        id: item.id,
                        clinic: item.clinic,
                        filial: item.filial,
                        name: item.name,
                        surname: item.surname,
                        lastname: item.lastname,
                        typesOfEmployee: item.typesOfEmployee,
                        direction: item.direction,
                        region: item.region,
                        city_district: item.city_district,
                        neighborhood: item.neighborhood,
                        home: item.home,
                        apartment_number: item.apartment_number,
                        image: logo 
                    });
                    logo = {};
                });
                return doctors;
            }
            return doctors;
        }
    },
    created() {
        this.getRegions();
    },
    methods: {
        ...mapActions({
            updateList: "doctors/index",
            setPagination: "doctors/setPagination",
            updateSort: "doctors/updateSort",
            updateFilter: "doctors/updateFilter",
            updateColumn: "doctors/updateColumn",
            updatePagination: "doctors/updatePagination",
            show: "doctors/show",
            empty: "doctors/empty",
            delete: "doctors/destroy",
            refreshData: "doctors/refreshData",
            getRegions: "region/inventory",
            getCityDistricts: 'region/filteredCityDistrict',
            remoteSearchMethod: 'doctors/remoteMethod',
        }),
        remoteMethod(query){
            if (query) {
                this.loading = true;
                setTimeout(() => {
                    this.remoteSearchMethod({query: query})
                    .then((res) => {
                        if (res.clinics) {
                            this.loading = false;
                            this.clinics = res.clinics;
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    }, 2000);
                });
            }else{
                this.clinics = [];
            }
        },
        clearData(clinic_id){
            if (!clinic_id) {
                this.debouncedFetchData();
                this.clinics = [];
            }
        },
        selectedRegionId(region_id){ 
            if (this.filterForm.city_district_id) {
                this.filterForm.city_district_id = null;
            }

            if (region_id) {  
                let query = {region_id: region_id};
                this.getCityDistricts(query);
            }else{
                this.$store.commit('region/EMPTY_CITY_DISTRICT');
            }
        },
        showProfil(doctor) {
            this.$router.push({path:'/doctorCardInfo/' + doctor.id});
        },
    },

    beforeRouteLeave(to, from, next) {
        this.$store.commit("doctors/EMPTY_LIST");
        next();
    },
};
</script>
<style lang="scss">
.mmi2.my-doctor-tabs .el-tabs__item {
    margin-bottom: 10px;
    font-size: 22px;
    background: var(--card-color);
    border: none !important;
    background-color: #0087af !important;
    margin: 0px 5px;
    border-radius: 4px !important;
    color: #fff !important;
}
.mmi2.my-doctor-tabs .el-tabs__nav-wrap {
    margin-top: -61px;
}
.mmi2 .el-tabs__item.is-top.is-active{
    background-color: #fff !important;
    color: #0087af !important;
    border: 1px solid #DCDFE6 !important;
    margin-left: 0px;
}
</style>
