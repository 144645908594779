import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
export default {
    props:{
        status: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            isManage: true,
            isWorkExperience: true,
            selectedPosition: null,
            fileStatus: false,
            currentStep: 1,
            dialogVisible: false,
            dialogImageUrl: null,
            disabled: false,
            updateImage: [],
            updateDoctorInformation: [],
            updateDoctorWorkExperences: [],
            updateDoctorCertificates: [],
            updateDoctorAcademicDegrees: [],
            doctor_informations: [{
                id: 1,
                higher_education_institution_id: null,
                specialization: null,
                when_it_started: "",
                diploma_serial_number: "",
                application_serial_number: "",
                fayls: []
            }],
            doctor_work_experiences: [{
                id: 1,
                work_experience: '',
                when_finished: '',
                how_many_years: '',
                qualification_category_id: null,
                received: null,
                when_received: '',
                what_documents: '',
                document_serial_number: '',
                document_date: '',
                name_organization: '',
                person_who_signed: '',
                fayls: [] 
            }],
            doctor_certificates: [{
                id: 1,
                name: '',
                which_specialization: null,
                when_received: '',
                what_documents: '',
                certificate_serial_number: '',
                certificate_date: '',
                name_organization: '',
                person_who_signed: '',
                fayls: []
            }],
            doctor_academic_degrees: [{
                id: 1,
                what_degree: '',
                where_taken: '',
                documents_signed: '',
                academic_degree_serial_number: '',
                fayls: []
            }],
            doctor_rank: [{
                command_number: '',
                person_who_signed: '',
                person_rank: '',
                command_date: '',
                command_serial_number: '',
                doctor_rank_items: [
                    {
                        in_management: '',
                        organization: '',
                        rank: ''
                    }
                ]
            }],
            old_informations: [],
            old_work_experiences: [],
            old_certificates: [],
            old_academic_degrees: [],
            old_rank: [],
            clinics: []
        }
    },
    computed: {
        ...mapGetters({
            model: "doctors/model",
            rules: "doctors/rules",
            regions: "region/inventory",
            filtered_city_district: 'region/filtered_city_district',
            filtered_filial: 'clinics/filtered_filial'
        }),
        steps() {
            return [
                {
                    step: 1,
                    stepText: "Qadam 1",
                },
                {
                    step: 2,
                    stepText: "Qadam 2",
                },
                {
                    step: 3,
                    stepText: "Qadam 3",
                },
            ];
        },
    },
    created() {
        this.getRegions();      
    },
    methods: {
        ...mapActions({
            getRegions: "region/inventory",
            getCityDistricts: 'region/filteredCityDistrict',
            getFilteredFilials: 'clinics/filteredFilial',
            empty: "doctors/empty",
            remoteSearchMethod: 'doctors/remoteMethod'
        }),
        submit(close = true) {
            let formData = new FormData();
            
            for (const key in this.form) {
                if (this.form[key]) {
                    formData.append(key, this.form[key]);
                }else{
                    formData.append(key, '');
                }
            }
            
            /**
                * Get Uplaod Images
            */
            if (_.size(this.updateImage) > 0) {
                for (const key in this.updateImage) {
                    if (this.updateImage.hasOwnProperty(key)) {
                        const element = this.updateImage[key];
                        formData.append(`images[${key}]`, element);
                    }
                }
            }
          
            /**
             * DOCTOR INFORMATIONS
            */
            let doctor_informations = this.doctor_informations.map( item => {
                return {
                    higher_education_institution_id: item.higher_education_institution_id,
                    specialization: item.specialization,
                    when_it_started: item.when_it_started,
                    diploma_serial_number: item.diploma_serial_number,
                    application_serial_number: item.application_serial_number,
						  date_of_give_diploma: item.date_of_give_diploma,
                    fayls: item.fayls
                }
            });
            this.buildFormData(formData, doctor_informations, 'doctor_informations');
            this.buildFormData(formData, this.old_informations, 'old_doctor_informations');

            /**
             * DOCTOR WORK EXPERIENCES
            */
            let doctor_work_experiences = this.doctor_work_experiences.map(item => {
                return {
                    work_experience: item.work_experience,
                    when_finished: item.when_finished,
                    how_many_years: item.how_many_years,
                    qualification_category_id: item.qualification_category_id,
                    received: item.received,
                    when_received: item.when_received,
                    what_documents: item.what_documents,
                    document_serial_number: item.document_serial_number,
                    document_date: item.document_date,
                    name_organization: item.name_organization,
                    person_who_signed: item.person_who_signed,
                    fayls: item.fayls 
                }
            });
            this.buildFormData(formData, doctor_work_experiences, 'doctor_work_experiences');
            this.buildFormData(formData, this.old_work_experiences, 'old_doctor_work_experiences')

            /**
             * DOCTOR CERTIFICATES
             */
            let doctor_certificates = this.doctor_certificates.map(item => {
                return {
                    name: item.name,
                    which_specialization: item.which_specialization,
                    when_received: item.when_received,
                    what_documents: item.what_documents,
                    certificate_serial_number: item.certificate_serial_number,
                    certificate_date: item.certificate_date,
                    name_organization: item.name_organization,
                    person_who_signed: item.person_who_signed,
                    fayls: item.fayls
                }
            });
            this.buildFormData(formData, doctor_certificates, 'doctor_certificates');
            this.buildFormData(formData, this.old_certificates, 'old_doctor_certificates');
            /**
             * DOCTOR ACADEMIC DEGREES
             */
            let doctor_academic_degrees = this.doctor_academic_degrees.map(item => {
                return {
                    academic_degree_id: item.academic_degree_id,
                    where_taken: item.where_taken,
                    documents_signed: item.documents_signed,
                    academic_degree_serial_number: item.academic_degree_serial_number,
                    fayls: item.fayls
                }
            });
            this.buildFormData(formData, doctor_academic_degrees, 'doctor_academic_degrees');
            this.buildFormData(formData, this.old_academic_degrees, 'old_doctor_academic_degrees');

            /**
             * DOCTOR RANK AND DOCTOR RANK ITEM
             */
            this.buildFormData(formData, this.doctor_rank, 'doctor_rank');
            for (const key in this.old_rank) {
                if (this.old_rank[key]) {
                     (key === 'rank_items') ? 
                     this.buildFormData(formData, this.old_rank[key], 'rank_items') : formData.append('rank_' + key, this.old_rank[key]);
                }else{
                    formData.append(key, '');
                }
            }
            //  console.log('formData', ...formData.entries());

            this.$refs["form"].validate((valid) => {
                if (valid && this.validateUplaodImages()) {
                    this.loadingButton = true;
                    this.save(formData)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },

        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
              Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
              });
            } else {
              const value = data == null ? '' : data;

              formData.append(parentKey, value);
            }
        },
        validateUplaodImages() {
            let has_status = false;
            let extensions = ['jpeg', 'jpg', 'png', 'svg', 'pdf', 'doc', 'docx', 'xls', 'xlsx']; //  
            let errors = [];

            // Validation for doctor image
            if (_.find(this.updateImage, function(o) { return (o.type != "image/jpeg" && o.type != "image/png") })) {
                this.$message({
                    message: this.$t('message.The image format must be JPEG, JPG, PNG'),
                    type: 'warning',
                    showClose: true
                });
                return false;
            }

            function clean_code(elem){
                elem.fayls.forEach(item => {
                    let ext = _.split(item.name, '.', 2);
                    let has_ext = extensions.includes(ext[1]);
                    if (!has_ext) {
                        if (!errors.includes(_.upperCase(ext[1]))) {
                            errors.push(_.upperCase(ext[1]));
                        }
                    }
                }); 
            }
           
            this.doctor_informations.forEach(elem => {
                clean_code(elem);
            });

            this.doctor_work_experiences.forEach(elem => {
                clean_code(elem);
            });

            this.doctor_certificates.forEach(elem => {
                clean_code(elem);
            });

            this.doctor_academic_degrees.forEach(elem => {
                clean_code(elem);
            }); 

            if (!_.isEmpty(errors)) {
                const h = this.$createElement;
                let mistake = errors.map(mime => h('li', `${mime}` + '  kengaytmali fayl yuklay olmaysiz'));
                const message = h('ul', mistake);
                    this.$message({
                        message: message,
                        type: 'warning',
                        showClose: true
                    });
                has_status = false;     
            }
            else{
                has_status = true;
            }

            let result = (has_status) ? true : false;   
            return result;   
        },

        setStep(step) {
            this.currentStep = step;
        },
        prevStep() {
            if (this.currentStep !== 1) {
                this.currentStep--;
            }
        },
        nextStep() {
            if (this.steps.length > this.currentStep) {
                this.currentStep++;
            }
        },

        addDoctorInformation(){
            let position = this.doctor_informations.length + 1;
            this.doctor_informations.push(
                {
                    id: position,
                    higher_education_institution_id: null,
                    specialization: "",
                    when_it_started: "",
                    diploma_serial_number: "",
                    application_serial_number: "",
						  date_of_give_diploma: "",
                    fayls: []
                }
            ); 
        },
        deleteItemDoctorInformation(index){
            if (index != 0)  delete this.doctor_informations.splice(index, 1);
        },
         
        addDoctorWorkExperences(){
            let position = this.doctor_work_experiences.length + 1;
            this.doctor_work_experiences.push(
                {
                    id: position,
                    work_experience: '',
                    when_finished: '',
                    how_many_years: '',
                    qualification_category_id: null,
                    received: '',
                    when_received: '',
                    what_documents: '',
                    document_serial_number: '',
                    document_date: '',
                    name_organization: '',
                    person_who_signed: '',
                    fayls: [] 
                }
            ); 
        },
        deleteItemDoctorWorkExperences(index){
           if (index != 0)  delete this.doctor_work_experiences.splice(index, 1);
        },

        addDoctorCertificates(){
            let position = this.doctor_certificates.length + 1;
            this.doctor_certificates.push(
                {
                    id: position,
                    name: '',
                    which_specialization: '',
                    when_received: '',
                    what_documents: '',
                    certificate_serial_number: '',
                    certificate_date: '',
                    name_organization: '',
                    person_who_signed: '',
                    fayls: []
                }
            ); 
        },
        deleteItemDoctorCertificates(index){
           if (index != 0) delete this.doctor_certificates.splice(index, 1);
        },

        addDoctorAcademicDegree(){
            let position = this.doctor_academic_degrees.length + 1;
            this.doctor_academic_degrees.push(
                {
                    id: position,
                    academic_degree_id: null,
                    where_taken: '',
                    documents_signed: '',
                    academic_degree_serial_number: '',
                    fayls: []
                }
            ); 
        },
        deleteItemDoctorAcademicDegree(index){
           if (index != 0) delete this.doctor_academic_degrees.splice(index, 1);
        },

        addDoctorRankItem(){
            if (!this.status) {
                let doctor_rank_items = this.doctor_rank[0]['doctor_rank_items'];  
                doctor_rank_items.push(
                    {
                        in_management: '',
                        organization: '',
                        rank: ''
                    }
                ); 
            }else{
                let new_rank_items = this.old_rank.rank_items;
                new_rank_items.push({
                    in_management: '',
                    organization: '',
                    rank: '' 
                });
            }
            
        },
        deleteItemDoctorRankItem(index){
            if (index != 0){ 
                if (!this.status) {
                    let doctor_rank_items = this.doctor_rank[0]['doctor_rank_items'];  
                    delete doctor_rank_items.splice(index, 1);
                }else{
                    let new_rank_items = this.old_rank[0]['rank_items'];
                    delete new_rank_items.splice(index, 1);
                }
            }  
        },

        /** Doctor Images */
        handleDownload(file) {
            console.log(file);
        },

        updateImageList(file) {
            this.updateImage.push(file.raw);  
        },
        handleRemove(file) {
            if (file.id) {
                this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                    this.$alert(res);
                }).catch(err => {});
            } else {
                for (var i = 0; i < _.size(this.updateImage); i++) {
                    if (this.updateImage[i].name === file.name) {
                        this.updateImage.splice(i, 1);
                    }
                }

            }
        },

        async getItemPosition(id, status) {
            this.selectedPosition = id;
            this.fileStatus = status;
        }, 

        updateDoctorInformationFile(file){
            let id = this.selectedPosition;
            if (!this.status) {
                this.doctor_informations.forEach(item => {
                    if (item.id == id){
                        item.fayls.push(file.raw);      
                    }
                }); 
                
            }else{
                if (!this.fileStatus) {
                    this.old_informations.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });     
                }else{
                    this.doctor_informations.forEach(item => {
                        if (item.id == id){
                            item.fayls.push(file.raw);
                        }
                    });    
                }
            }
              
        },
        handleDoctorInformationFileRemove(file){ 
            for (let i = 0; i < _.size(this.doctor_informations); i++) {
                for (let j = 0; j < _.size(this.doctor_informations[i].fayls); j++) {
                    if (this.doctor_informations[i].fayls[j].name === file.name) {
                        this.doctor_informations[i].fayls.splice(j, 1);
                    }
                }
            }              
        },
        handleDoctorInformationFileRemoveOld(file){
            for (let i = 0; i < _.size(this.old_informations); i++) {
                for (let j = 0; j < _.size(this.old_informations[i].fayls); j++) {
                    if (this.old_informations[i].fayls[j].name === file.name) {
                        this.old_informations[i].fayls.splice(j, 1);
                    }
                }
            }   
        },

        updateDoctorWorkExperencesFile(file){
            let id = this.selectedPosition;
            if (!this.status) {
                this.doctor_work_experiences.forEach(item => {
                    if (item.id == id) {
                        item.fayls.push(file.raw);
                    }
                });
            }else{
                if (!this.fileStatus){
                    this.old_work_experiences.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });       
                }else{
                    this.doctor_work_experiences.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });        
                }
            }      
        },
        handleDoctorWorkExperencesFileRemove(file){
            for (var i = 0; i < _.size(this.doctor_work_experiences); i++) {
                for(let j = 0; j < _.size(this.doctor_work_experiences[i].fayls); j++){   
                    if (this.doctor_work_experiences[i].fayls[j].name === file.name) {
                        this.doctor_work_experiences[i].fayls.splice(j, 1);
                    }
                }
            }
        },
        handleDoctorWorkExperencesFileRemoveOld(file){
            for (var i = 0; i < _.size(this.old_work_experiences); i++) {
                for(let j = 0; j < _.size(this.old_work_experiences[i].fayls); j++){   
                    if (this.old_work_experiences[i].fayls[j].name === file.name) {
                        this.old_work_experiences[i].fayls.splice(j, 1);
                    }
                }
            }
        },

        updateDoctorCertificatesFile(file){
            let id = this.selectedPosition;

            if (!this.status) {
                this.doctor_certificates.forEach(item => {
                    if (item.id == id) {
                        item.fayls.push(file.raw);
                    }
                });
            }else{
                if (!this.fileStatus){
                    this.old_certificates.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });       
                }else{
                    this.doctor_certificates.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });        
                }
            }
        },
        handleDoctorCertificatesFileRemove(file){
            for (var i = 0; i < _.size(this.doctor_certificates); i++) {
                for(let j = 0; j < _.size(this.doctor_certificates[i].fayls); j++){   
                    if (this.doctor_certificates[i].fayls[j].name === file.name) {
                        this.doctor_certificates[i].fayls.splice(j, 1);
                    }
                }
            }
        },

        handleDoctorCertificatesFileRemoveOld(file){
            for (var i = 0; i < _.size(this.old_certificates); i++) {
                for(let j = 0; j < _.size(this.old_certificates[i].fayls); j++){   
                    if (this.old_certificates[i].fayls[j].name === file.name) {
                        this.old_certificates[i].fayls.splice(j, 1);
                    }
                }
            }
        },

        updateDoctorAcademicDegreeFile(file){
            let id = this.selectedPosition;
           
            if (!this.status) {
                this.doctor_academic_degrees.forEach(item => {
                    if (item.id == id) {
                        item.fayls.push(file.raw);
                    }
                });
            }else{
                if (!this.fileStatus){
                    this.old_academic_degrees.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });       
                }else{
                    this.doctor_academic_degrees.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });        
                }
            }
        },

        handleDoctorAcademicDegreeFileRemove(file){
            for (var i = 0; i < _.size(this.doctor_academic_degrees); i++) {
                for(let j = 0; j < _.size(this.doctor_academic_degrees[i].fayls); j++){   
                    if (this.doctor_academic_degrees[i].fayls[j].name === file.name) {
                        this.doctor_academic_degrees[i].fayls.splice(j, 1);
                    }
                }
            } 
        },

        handleDoctorAcademicDegreeFileRemoveOld(file){
            for (var i = 0; i < _.size(this.old_academic_degrees); i++) {
                for(let j = 0; j < _.size(this.old_academic_degrees[i].fayls); j++){   
                    if (this.old_academic_degrees[i].fayls[j].name === file.name) {
                        this.old_academic_degrees[i].fayls.splice(j, 1);
                    }
                }
            } 
        },

        selectedRegionId(region_id){ 

            if (this.form.city_district_id) {
                this.form.city_district_id = null;
            }

            if (region_id) {  
                let query = {region_id: region_id};
                this.getCityDistricts(query)
                .then((result) => {
                    
                }).catch((err) => {
                    console.log(err); 
                });
            }else{
                this.$store.commit('region/EMPTY_CITY_DISTRICT');
            }
        },
        remoteMethod(query){
            if (_.isNumber(query)){
                this.loading = true;
                this.remoteSearchMethod({query: query}).then((res) => {
                    if (res.clinics) {
                        this.loading = false;
                        this.clinics = res.clinics;
                    }
                }).catch((err) => {
                    this.loading = false; 
                    console.log('err', err);
                });
            }
            else{
                if (query) {
                    this.loading = true;
                    setTimeout(() => {
                        this.remoteSearchMethod({query: query})
                        .then((res) => {
                            if (res.clinics) {
                                this.loading = false;
                                this.clinics = res.clinics;
                            }
                        }).catch((err) => {
                            this.loading = false;
                            console.log(err);
                        }, 2000);
                    });
                }else{
                    this.clinics = [];
                }
            }
        },
        selectedClinicId(clinic_id){
            if (clinic_id) {
                let query = {clinic_id: clinic_id};
                this.getFilteredFilials(query);    
            }else{
                this.clinics = [];
                this.$store.commit('clinics/EMPTY_FILIAL');
            }
        },
        selectedWorkExperience(status){    
            if (status || !_.isNumber(status)) {
                this.isWorkExperience = true;
            }
            else{
                this.isWorkExperience = !this.isWorkExperience;
            }
        },
        rankManage(status){
            if (status || !_.isNumber(status)) {
                this.isManage = true;
            }
            else{
                this.isManage = !this.isManage;
            }
        },
        isLetterOrNumber(e, position){
            let status = false;
            let char = String.fromCharCode(e.keyCode); // Get the character
       
            if(/^[A-Za-z0-9]+$/.test(char)){
                status = true;
            }
            else e.preventDefault();

            if (status) {

                [...this.doctor_informations, ...this.old_informations].forEach((item, index) => {
                    if (index == position) {
                        if (item.diploma_serial_number.length == 0) {
                            return true;
                        }
                        else if (item.diploma_serial_number.length <= 1) {
                            if (e.code.substr(0,3) == 'Key') {
                                e.preventDefault();
                            }
                        }
                        else {
                            if (item.diploma_serial_number.charCodeAt(0) >= 65 && item.diploma_serial_number.charCodeAt(0) <= 122) {
                                let num_len = item.diploma_serial_number.substr(1).length;
                                if (num_len >= 7) {
                                    e.preventDefault();
                                }
                            }
                            else{
                                if(/^[0-9]*$/.test(char)){
                                    let num_len = item.diploma_serial_number.substr(0).length;
                                    if (num_len >= 6) {
                                        e.preventDefault();
                                    }
                                }
                                else e.preventDefault();
                            }
                        }
                    }
                });
           }
        },

        isLetterOrNumberForCertificate(e, position){
            let status = false;
            let char = String.fromCharCode(e.keyCode); // Get the character
          
            if(/^[A-Za-z0-9]+$/.test(char)){
                status = true;
            }
            else e.preventDefault();

            if (status) {
                [...this.doctor_certificates, ...this.old_certificates].forEach((item, index) => {
                    if (index == position) {
                        if (e.code.substr(0,3) == 'Key') {
                            if (item.certificate_serial_number.length <= 2) {
                                return true;
                            }
                            else e.preventDefault();
                        }
                        else{
                            let num_len = 0;
                            if ((item.certificate_serial_number.charCodeAt(0) >= 65 && item.certificate_serial_number.charCodeAt(0) <= 122) 
                                && (item.certificate_serial_number.charCodeAt(1) >= 65 && item.certificate_serial_number.charCodeAt(1) <= 122) 
                                && (item.certificate_serial_number.charCodeAt(2) >= 65 && item.certificate_serial_number.charCodeAt(2) <= 122)) {
                                num_len = item.certificate_serial_number.substr(3).length; 
                            }
                            else if ((item.certificate_serial_number.charCodeAt(0) >= 65 && item.certificate_serial_number.charCodeAt(0) <= 122) 
                                && (item.certificate_serial_number.charCodeAt(1) >= 65 && item.certificate_serial_number.charCodeAt(1) <= 122)) {
                                num_len = item.certificate_serial_number.substr(2).length; 
                            }
                            else {
                                num_len = item.certificate_serial_number.substr(0).length;
                            }
                            if (num_len >= 6) e.preventDefault();
                        }
                    }
                });
            }
        },
    },
}
