<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{ $t("message.doctor") }}  {{ $t("message.new_c") }} 
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="modal-content">
             <div class="card-body p30">
                <div class="steps">
                    <ul class="steps-ul">
                        <li
                            v-for="(item, itemIndex) in steps"
                            :key="itemIndex"
                            :class="{ active: item.step === currentStep }"
                            @click="setStep(item.step)"
                        >
                            {{ item.stepText }}
                        </li>
                    </ul>
                </div>

                <div class="steps-body">
                    <el-form ref="form" :model="form" :rules="rules" class="my-form">
                        <div v-if="currentStep === 1" class="step-itme-1">
                            <el-row :gutter="20">
                                <el-col :span="10">
                                    <el-form-item label="Klinika" prop="clinic_id">
                                        <el-select
                                            v-model="form.clinic_id"
                                            filterable
                                            clearable
                                            remote
                                            :placeholder="$t('message.clinics')"
                                            :remote-method="remoteMethod"
                                            @change="selectedClinicId"
                                            :loading="loading"
                                            size="large">
                                            <el-option
                                            v-for="item in clinics"
                                            :key="item.id"
                                            :label="item.legal_entity_name"
                                            :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="10">
                                    <el-form-item label="Filial">
                                        <el-select
                                            v-model="form.filial_id"
                                            placeholder="Filial"
                                            clearable
                                            filterable
                                        >
                                            <el-option
                                                v-for="(
                                                    filial, index
                                                ) in filtered_filial"
                                                :key="'filial-' + index"
                                                :label="
                                                    filial.legal_entity_name +
                                                    ' / ' +
                                                    filial.brand_name
                                                "
                                                :value="filial.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="10">
                                    <el-form-item label="Xodimlar yo'nalishi" prop="direction_id">
                                        <select-directions
                                            :id="form.direction_id"
                                            v-model="form.direction_id"
                                            :size="'medium'"
                                        >
                                        </select-directions>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="10">
                                    <el-form-item label="Xodimlar turlari" prop="types_of_employee_id">
                                        <select-employee-type
                                            :id="form.types_of_employee_id"
                                            v-model="form.types_of_employee_id"
                                            :size="'medium'"
                                        >
                                        </select-employee-type>
                                    </el-form-item>
                                </el-col>

                            </el-row>

                            <el-row :gutter="20">
                                <el-divider content-position="left">
                                    Shaxsiy ma'lumotlar
                                </el-divider>

                                <el-col :span="8">
                                    <el-form-item label="Ism" prop="name">
                                        <el-input
                                            v-model="form.name"
                                            placeholder="Ism"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Familiya" prop="surname">
                                        <el-input
                                            v-model="form.surname"
                                            placeholder="Familiya"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Otasining ismi">
                                        <el-input
                                            v-model="form.lastname"
                                            placeholder="Otasining ismi"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="ID">
                                        <el-input
                                            v-model="form.id_number"
                                            placeholder="ID"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item
                                        label="Pasport seriyasi va raqami"
                                    >
                                        <crm-input 
                                            v-model="form.passport_series_and_number"  
                                            :placeholder="'AA1111010'"
                                            :maskFormat="'passport'"
                                        ></crm-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Tug'ilgan sana">
                                        <el-date-picker
                                            type="datetime"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="Tug'ilgan sana"
                                            v-model="form.brith_date"
                                            style="width: 100%"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="STIR">
                                        <crm-input
                                            v-model="form.stir"
                                            :placeholder="'123456789'"
                                            :maskFormat="'stir'"
                                        ></crm-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="INN">
                                        <crm-input
                                            v-model="form.inn"
                                            :placeholder="'123456789'"
                                            :maskFormat="'inn'"
                                        ></crm-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item
                                        label="Elektron pochta manzili"
                                    >
                                        <el-input
                                            v-model="form.email"
                                            placeholder="Elektron pochta manzili"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Ish telefoni raqami" prop="work_tel_number">
                                        <crm-input
                                        :inputValue="form.work_tel_number"
                                          v-model="form.work_tel_number"
                                          placeholder="+998999999999" 
                                           :maskFormat="'tel'"
                                        ></crm-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Mobil telefoni raqami" prop="mobile_tel_number">
                                        <crm-input
                                        :inputValue="form.mobile_tel_number"
                                          v-model="form.mobile_tel_number"
                                          placeholder="+998999999999" 
                                           :maskFormat="'tel'"
                                        ></crm-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Jinsi">
                                        <el-select
                                            v-model="form.gender"
                                            placeholder="Jinsi"
                                        >
                                            <el-option
                                                label="Erkak"
                                                value="Erkak"
                                            ></el-option>
                                            <el-option
                                                label="Ayol"
                                                value="Ayol"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Millati">
                                        <select-nation
                                            :id="form.nation_id"
                                            v-model="form.nation_id"
                                            :size="'medium'"
                                        >
                                        </select-nation>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Tug'ilgan joyi">
                                        <el-input
                                            v-model="form.place_of_brith"
                                            placeholder="Tug'ilgan joyi"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-col :span="24" class="mt20">
                                    <el-divider content-position="left">
                                        Yashash manzil
                                    </el-divider>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Viloyat" prop="region_id">
                                        <el-select
                                            v-model="form.region_id"
                                            @change="selectedRegionId"
                                            placeholder="Viloyat"
                                            filterable
                                            clearable
                                        >
                                            <el-option
                                                v-for="(
                                                    region, index
                                                ) in regions"
                                                :key="'region-' + index"
                                                :label="region.region_name"
                                                :value="region.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Shahar va Tuman" prop="city_district_id">
                                        <el-select
                                            v-model="form.city_district_id"
                                            placeholder="Shahar va Tuman"
                                            filterable
                                            clearable
                                        >
                                            <el-option
                                                v-for="(
                                                    city, index
                                                ) in filtered_city_district"
                                                :key="'city-' + index"
                                                :label="city.name"
                                                :value="city.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Mahalla">
                                        <el-input
                                            v-model="form.neighborhood"
                                            placeholder="Mahalla"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Uy">
                                        <el-input
                                            v-model="form.home"
                                            placeholder="Uy"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Xonadon">
                                        <el-input
                                            v-model="form.apartment_number"
                                            placeholder="Xonadon"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="24">
                                    <el-form-item label="Xodimning rasmi">
                                        <div class="imgUpload">
                                            <el-upload
                                                class="upload-demo w-100"
                                                action="/"
                                                :limit="1"
                                                :on-change="updateImageList"
                                                :on-remove="handleRemove"
                                                accept="image/*"
                                                name="image"
                                                list-type="picture-card"
                                                :auto-upload="false"
                                                ref="img_upload"
                                            >
                                                <i
                                                    slot="default"
                                                    class="el-icon-plus"
                                                ></i>
                                            </el-upload>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="step-btn">
                                <el-button
                                    class="asosy-btn-d"
                                    @click="nextStep"
                                    type="primary"
                                    icon="el-icon-right"
                                >
                                    Keyingi
                                </el-button>
                            </div>
                        </div>

                        <div v-if="currentStep === 2" class="step-itme-2">
                            <el-row
                                :gutter="20"
                                v-for="(item, index) in doctor_informations"
                                :key="index"
                            >
                                <el-divider content-position="left">
                                    Shaxsiy ma'lumotlar
                                </el-divider>

                                <el-col :span="8">
                                    <el-form-item
                                        label="Oliy o'quv yurti"
                                        class="icons-input"
                                    >
                                        <select-higher-educations
                                            :id="
                                                item.higher_education_institution_id
                                            "
                                            v-model="
                                                item.higher_education_institution_id
                                            "
                                            :size="'medium'"
                                        >
                                        </select-higher-educations>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Mutaxassislik">
                                        <select-directions
                                            :id="item.specialization"
                                            v-model="item.specialization"
                                            :size="'medium'"
                                        >
                                        </select-directions>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Qachon boshlangan">
                                        <el-date-picker
                                            v-model="item.when_it_started"
                                            type="month"
                                            format="yyyy-MM"
                                            value-format="yyyy-MM"
                                            placeholder="Qachon boshlangan"
                                            size='medium'
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Diplom seriya raqami">
                                        <el-input
                                            v-model="item.diploma_serial_number"
                                            placeholder="Diplom seriya raqami"
                                            size='medium'
                                            @keypress.native="isLetterOrNumber($event, index)"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Ilova seriya raqami">
                                        <crm-input
                                            v-model="item.application_serial_number"
                                            placeholder="Ilova seriya raqami"
                                            size='medium'
                                        >
                                        </crm-input>
                                    </el-form-item>
                                </el-col>
										  <el-col :span="8">
												<el-form-item label="Diplom berilgan sana">
													<el-date-picker
													 	v-model="item.date_of_give_diploma"
														type="date"
														format="yyyy-MM-dd"
														value-format="yyyy-MM-dd"
														placeholder="Diplom berilgan sana"
													>
													</el-date-picker>
												</el-form-item>
										  </el-col>
                                <el-col :span="12">
                                    <el-form-item label="File">
                                        <div class="imgUpload">
                                            <el-upload
                                                class="upload-demo"
                                                action="/"
                                                :limit="3"
                                                accept="file/*"
                                                name="file"
                                                multiple
                                                :file-list="item.fayls"
                                                @input.native="
                                                    getItemPosition(item.id, true)
                                                "
                                                :auto-upload="false"
                                                :on-change="
                                                    updateDoctorInformationFile
                                                "
                                                :on-remove="
                                                    handleDoctorInformationFileRemove
                                                "
                                                list-type="text"
                                            >
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                    >Click to upload</el-button
                                                >
                                                <div
                                                    slot="tip"
                                                    class="el-upload__tip"
                                                >
                                                    jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                </div>
                                            </el-upload>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <i
                                    class="delet-icon el-icon-circle-close"
                                    @click="deleteItemDoctorInformation(index)"
                                ></i>
                            </el-row>
                            <div class="w-100">
                                <div class="add-form-educet">
                                    <el-button
                                        class="asosy-btn-d"
                                        icon="el-icon-plus"
                                        @click="addDoctorInformation()"
                                    >
                                        Qo'shish
                                    </el-button>
                                </div>
                            </div>
                            <div class="step-btn">
                                <el-button
                                    class="asosy-btn-y"
                                    @click="prevStep"
                                    icon="el-icon-back"
                                >
                                    Oldingi
                                </el-button>
                                <el-button
                                    class="asosy-btn-d"
                                    @click="nextStep"
                                    type="primary"
                                    icon="el-icon-right"
                                >
                                    Keyingi
                                </el-button>
                            </div>
                        </div>
                        <div v-if="currentStep === 3" class="step-itme-3">
                            <div class="form-tabs-doctor-info">
                                <el-tabs type="border-card">
                                    <el-tab-pane label="Ish Tajribasi">
                                        <el-row
                                            :gutter="20"
                                            v-for="(
                                                item, index
                                            ) in doctor_work_experiences"
                                            :key="
                                                'doctor_work_experiences-' +
                                                index
                                            "
                                        >
                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ish Tajribasi "
                                                    class="icons-input"
                                                >
                                                    <el-select
                                                        v-model="
                                                            item.work_experience
                                                        "
                                                        @change="selectedWorkExperience"
                                                        placeholder="Ish Tajribasi"
                                                        clearable filterable
                                                    >
                                                        <el-option
                                                            label="Ha"
                                                            :value="1"
                                                        ></el-option>
                                                        <el-option
                                                            label="Yo'q"
                                                            :value="0"
                                                        ></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8" v-show="isWorkExperience">
                                                <el-form-item
                                                    label="Qachon tugatgan"
                                                >
                                                    <el-date-picker
                                                        v-model="
                                                            item.when_finished
                                                        "
                                                        type="month"
                                                        format="yyyy-MM"
                                                        value-format="yyyy-MM"
                                                        placeholder="Qachon tugatgan"
                                                    >
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8" v-show="isWorkExperience">
                                                <el-form-item
                                                    label="Necha yil "
                                                    class="icons-input"
                                                >
                                                <el-input-number v-model="item.how_many_years" :min="0"></el-input-number>

                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <el-form-item
                                                    label="Ish Malakasi 5 yildan oshganmi? (Ixtisosligi Malaka toyifasi bormi)"
                                                >
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Malaka Toifasi"
                                                    class="icons-input"
                                                >
                                                    <select-qualification-categorie
                                                        :id="
                                                            item.qualification_category_id
                                                        "
                                                        v-model="
                                                            item.qualification_category_id
                                                        "
                                                        :size="'medium'"
                                                    >
                                                    </select-qualification-categorie>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Qaysi Ixtisosligi bo'yicha malaka toifasi olgan"
                                                >
                                                <select-directions
                                                    :id="item.received"
                                                    v-model="item.received"
                                                    :size="'medium'"
                                                >
                                                </select-directions>
                                                    <!-- <el-input
                                                        v-model="item.received"
                                                        placeholder="Qaysi Ixtisosligi bo'yicha malaka toifasi olgan"
                                                    ></el-input> -->
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Malaka toifasi qachon olgan"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="Malaka toifasi qachon olgan"
                                                        v-model="
                                                            item.when_received
                                                        "
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Malaka toifasi qanday hujjatlar asosida berilgan"
                                                >
                                                    <el-input
                                                        v-model="
                                                            item.what_documents
                                                        "
                                                        placeholder="Malaka toifasi qanday hujjatlar asosida berilgan"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Hujjata seriyasi raqami"
                                                >
                                                    <el-input
                                                        v-model="
                                                            item.document_serial_number
                                                        "
                                                        placeholder="Hujjata seriyasi raqami "
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Hujjata Sanasi"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="Hujjata Sanasi"
                                                        v-model="
                                                            item.document_date
                                                        "
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Hujjata Bergan tashkilot nomi"
                                                >
                                                    <el-input
                                                        placeholder="Hujjata Bergan tashkilot nomi"
                                                        v-model="
                                                            item.name_organization
                                                        "
                                                        style="width: 100%"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Hujjat imzolagan shaxs FISH"
                                                >
                                                    <el-input
                                                        placeholder="Hujjat imzolagan shaxs FISH"
                                                        v-model="
                                                            item.person_who_signed
                                                        "
                                                        style="width: 100%"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="imgUpload">
                                                    <el-upload
                                                        class="upload-demo"
                                                        action="/"
                                                        :limit="3"
                                                        accept="file/*"
                                                        name="file"
                                                        multiple
                                                        :file-list="item.fayls"
                                                        @input.native="
                                                            getItemPosition(
                                                                item.id
                                                            )
                                                        "
                                                        :auto-upload="false"
                                                        :on-change="
                                                            updateDoctorWorkExperencesFile
                                                        "
                                                        :on-remove="
                                                            handleDoctorWorkExperencesFileRemove
                                                        "
                                                        list-type="text"
                                                    >
                                                        <el-button
                                                            size="small"
                                                            type="primary"
                                                            >Click to
                                                            upload</el-button
                                                        >
                                                        <div
                                                            slot="tip"
                                                            class="
                                                                el-upload__tip
                                                            "
                                                        >
                                                            jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                        </div>
                                                    </el-upload>
                                                </div>
                                                <i
                                                    class="
                                                        delet-icon
                                                        el-icon-circle-close
                                                    "
                                                    @click="
                                                        deleteItemDoctorWorkExperences(
                                                            index
                                                        )
                                                    "
                                                ></i>
                                            </el-col>
                                        </el-row>
                                        <div class="w-100">
                                            <div class="add-form-educet">
                                                <el-button
                                                    class="asosy-btn-d"
                                                    icon="el-icon-plus"
                                                    @click="
                                                        addDoctorWorkExperences
                                                    "
                                                >
                                                    Qo'shish
                                                </el-button>
                                            </div>
                                        </div>
                                    </el-tab-pane>

                                    <el-tab-pane label="Sertifikatlar">
                                        <el-row
                                            :gutter="20"
                                            v-for="(
                                                item, index
                                            ) in doctor_certificates"
                                            :key="
                                                'doctor_certificates-' + index
                                            "
                                        >
                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikatlar nomi"
                                                >
                                                    <el-input
                                                        v-model="item.name"
                                                        placeholder="Sertifikatlar nomi"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Qaysi ixtisosligi sertifikatlar"
                                                >
                                                <select-directions
                                                    :id="item.which_specialization"
                                                    v-model="item.which_specialization"
                                                    :size="'medium'"
                                                >
                                                </select-directions>
                                                    <!-- <el-input
                                                        v-model="
                                                            item.which_specialization
                                                        "
                                                        placeholder="Qaysi ixtisosligi sertifikatlar"
                                                    ></el-input> -->
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar  Sanasi"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="Sertifikarlar  Sanasi"
                                                        v-model="
                                                            item.certificate_date
                                                        "
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar qanday hujjatlar asosida berilgan"
                                                >
                                                    <el-input
                                                        v-model="
                                                            item.what_documents
                                                        "
                                                        placeholder="Sertifikarlar qanday hujjatlar asosida berilgan"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar seriyasi raqami"
                                                >
                                                    <el-input
                                                        v-model="item.certificate_serial_number"
                                                        placeholder="Sertifikarlar seriyasi raqami"
                                                        @keypress.native="isLetterOrNumberForCertificate($event, index)"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikat amal qilish muddati"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="Sertifikat amal qilish muddati"
                                                        v-model="item.when_received"
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar Bergan tashkilot nomi"
                                                >
                                                    <el-input
                                                        placeholder="Sertifikarlar Bergan tashkilot nomi"
                                                        v-model="
                                                            item.name_organization
                                                        "
                                                        style="width: 100%"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Sertifikarlar imzolagan shaxs FISH"
                                                >
                                                    <el-input
                                                        placeholder="Sertifikarlar imzolagan shaxs FISH"
                                                        v-model="
                                                            item.person_who_signed
                                                        "
                                                        style="width: 100%"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="imgUpload">
                                                    <el-upload
                                                        class="upload-demo"
                                                        action="/"
                                                        :limit="3"
                                                        accept="file/*"
                                                        name="file"
                                                        multiple
                                                        :file-list="item.fayls"
                                                        @input.native="
                                                            getItemPosition(
                                                                item.id
                                                            )
                                                        "
                                                        :auto-upload="false"
                                                        :on-change="
                                                            updateDoctorCertificatesFile
                                                        "
                                                        :on-remove="
                                                            handleDoctorCertificatesFileRemove
                                                        "
                                                        list-type="text"
                                                    >
                                                        <el-button
                                                            size="small"
                                                            type="primary"
                                                            >Click to
                                                            upload</el-button
                                                        >
                                                        <div
                                                            slot="tip"
                                                            class="
                                                                el-upload__tip
                                                            "
                                                        >
                                                            jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                        </div>
                                                    </el-upload>
                                                </div>
                                                <i
                                                    class="
                                                        delet-icon
                                                        el-icon-circle-close
                                                    "
                                                    @click="
                                                        deleteItemDoctorCertificates(
                                                            index
                                                        )
                                                    "
                                                ></i>
                                            </el-col>
                                        </el-row>
                                        <div class="w-100">
                                            <div class="add-form-educet">
                                                <el-button
                                                    class="asosy-btn-d"
                                                    icon="el-icon-plus"
                                                    @click="
                                                        addDoctorCertificates
                                                    "
                                                >
                                                    Qo'shish
                                                </el-button>
                                            </div>
                                        </div>
                                    </el-tab-pane>

                                    <el-tab-pane label="Ilmiy Daraja">
                                        <el-row
                                            :gutter="20"
                                            v-for="(
                                                item, index
                                            ) in doctor_academic_degrees"
                                            :key="
                                                'doctor_academic_degrees-' +
                                                index
                                            "
                                        >
                                            <!-- <el-col :span="24">
                                                    <el-form-item
                                                        label="Ilmiy daraja bormi"
                                                    >
                                                        <el-checkbox-group
                                                            v-model="form.y8"
                                                        >
                                                            <el-checkbox
                                                                label="HA"
                                                                name="type"
                                                            >
                                                            </el-checkbox>
                                                            <el-checkbox
                                                                label="YOQ"
                                                                name="type"
                                                            >
                                                            </el-checkbox>
                                                        </el-checkbox-group>
                                                    </el-form-item>
                                                </el-col> -->
                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qnday"
                                                    class="icons-input"
                                                >
                                                <select-academic-dgrees
                                                    :id="item.academic_degree_id"
                                                    v-model="item.academic_degree_id"
                                                    :size="'medium'"
                                                >
                                                </select-academic-dgrees>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qayerda va qanday olingan"
                                                >
                                                    <el-input
                                                        v-model="
                                                            item.where_taken
                                                        "
                                                        placeholder="Ilmiy Daraja qayerda va qanday olingan"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qanday hujatlar bilan imzolangan"
                                                >
                                                    <el-input
                                                        v-model="
                                                            item.documents_signed
                                                        "
                                                        placeholder="Ilmiy Daraja qanday hujatlar bilan imzolangan"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qanday hujatlar bilan imzolangan seriyasi raqami"
                                                >
                                                    <el-input
                                                        v-model="
                                                            item.academic_degree_serial_number
                                                        "
                                                        placeholder="Ilmiy Daraja qanday hujatlar bilan imzolangan seriyasi raqami "
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="imgUpload">
                                                    <el-upload
                                                        class="upload-demo"
                                                        action="/"
                                                        :limit="3"
                                                        accept="file/*"
                                                        name="file"
                                                        multiple
                                                        :file-list="item.fayls"
                                                        @input.native="
                                                            getItemPosition(
                                                                item.id
                                                            )
                                                        "
                                                        :auto-upload="false"
                                                        :on-change="
                                                            updateDoctorAcademicDegreeFile
                                                        "
                                                        :on-remove="
                                                            handleDoctorAcademicDegreeFileRemove
                                                        "
                                                        list-type="text"
                                                    >
                                                        <el-button
                                                            size="small"
                                                            type="primary"
                                                            >Click to
                                                            upload</el-button
                                                        >
                                                        <div
                                                            slot="tip"
                                                            class="
                                                                el-upload__tip
                                                            "
                                                        >
                                                            jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                        </div>
                                                    </el-upload>
                                                </div>
                                                <i
                                                    class="
                                                        delet-icon
                                                        el-icon-circle-close
                                                    "
                                                    @click="
                                                        deleteItemDoctorAcademicDegree(
                                                            index
                                                        )
                                                    "
                                                ></i>
                                            </el-col>
                                        </el-row>
                                        <div class="w-100">
                                            <div class="add-form-educet">
                                                <el-button
                                                    class="asosy-btn-d"
                                                    icon="el-icon-plus"
                                                    @click="
                                                        addDoctorAcademicDegree
                                                    "
                                                >
                                                    Qo'shish
                                                </el-button>
                                            </div>
                                        </div>
                                    </el-tab-pane>

                                    <el-tab-pane label="Lavozim">
                                        <el-row
                                            :gutter="20"
                                            v-for="(rank, index) in doctor_rank"
                                            :key="'doctor_rank-' + index"
                                        >
                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Buyuruq no'meri"
                                                >
                                                    <el-input
                                                        v-model="
                                                            rank.command_number
                                                        "
                                                        placeholder="Buyuruq no'meri"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Buyuruq imolagan shaxs  FISH"
                                                >
                                                    <el-input
                                                        v-model="rank.person_who_signed"
                                                        placeholder="Buyuruq imolagan shaxs  FISH"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Buyuruq imzolagan shaxs Lavozmi"
                                                >
                                                    <el-input
                                                        v-model="
                                                            rank.person_rank
                                                        "
                                                        placeholder="Buyuruq imzolagan shaxs Lavozmi"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Buyuruq sanasi"
                                                >
                                                    <el-date-picker
                                                        type="date"
                                                        format="yyyy-MM-dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="Buyuruq sanasi"
                                                        v-model="
                                                            rank.command_date
                                                        "
                                                        style="width: 100%"
                                                    ></el-date-picker>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :span="8">
                                                <el-form-item
                                                    label="Ilmiy Daraja qanday hujatlar bilan imzolangan seriyasi raqami"
                                                >
                                                    <el-input
                                                        v-model="
                                                            rank.command_serial_number
                                                        "
                                                        placeholder="Ilmiy Daraja qanday hujatlar bilan imzolangan seriyasi raqami "
                                                    ></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="24">
                                                <el-divider
                                                    content-position="left"
                                                >
                                                    Boshqa Tibbiy muassasalarda
                                                    boshqaruvda ishlaganmi
                                                </el-divider>
                                                <el-row
                                                    :gutter="20"
                                                    v-for="(
                                                        item, index
                                                    ) in rank.doctor_rank_items"
                                                    :key="
                                                        'doctor_rank_items-' +
                                                        index
                                                    "
                                                >
                                                    <el-col :span="8">
                                                        <el-form-item
                                                            label="Boshqaruvda "
                                                        >
                                                            <el-select
                                                                v-model="
                                                                    item.in_management
                                                                "
                                                                @change="rankManage"
                                                                clearable filterable
                                                                placeholder="Boshqaruvda"
                                                            >
                                                                <el-option
                                                                    label="Ha"
                                                                    :value="1"
                                                                ></el-option>
                                                                <el-option
                                                                    label="Yoq"
                                                                    :value="0"
                                                                ></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                    </el-col>

                                                    <el-col :span="8" v-show="isManage">
                                                        <el-form-item
                                                            label="Qaysi Tashkilot ishlagan"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    item.organization
                                                                "
                                                                placeholder="Qaysi Tashkilot ishlagan "
                                                            ></el-input>
                                                        </el-form-item>
                                                    </el-col>

                                                    <el-col :span="8" v-show="isManage">
                                                        <el-form-item
                                                            label="Qaysi lavozmida ishlgan"
                                                        >
                                                            <el-input
                                                                v-model="
                                                                    item.rank
                                                                "
                                                                placeholder="Qaysi Tashkilot ishlagan "
                                                            ></el-input>
                                                        </el-form-item>
                                                        <i
                                                            class="
                                                                delet-icon
                                                                el-icon-circle-close
                                                            "
                                                            @click="
                                                                deleteItemDoctorRankItem(
                                                                    index
                                                                )
                                                            "
                                                        ></i>
                                                    </el-col>
                                                </el-row>
                                                <div class="w-100">
                                                    <div
                                                        class="add-form-educet"
                                                    >
                                                        <el-button
                                                            class="asosy-btn-d"
                                                            icon="el-icon-plus"
                                                            @click="
                                                                addDoctorRankItem
                                                            "
                                                        >
                                                            Qo'shish
                                                        </el-button>
                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                            <div class="step-btn">
                                <el-button
                                    class="asosy-btn-y"
                                    @click="prevStep"
                                    icon="el-icon-back"
                                >
                                    Oldingi
                                </el-button>
                                <el-button
                                    class="asosy-btn-d"
                                    @click="nextStep"
                                    type="primary"
                                    icon="el-icon-right"
                                >
                                    Keyingi
                                </el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import doctor from "../../../utils/mixins/models/doctor";
import form from "../../../utils/mixins/form";
import drawer from '../../../utils/mixins/drawer';
import selectEmployeeType from "../../../components/filters/inventory/select-employee-type.vue";
import SelectHigherEducations from "../../../components/filters/inventory/select-higher-educations.vue";
import SelectQualificationCategorie from "../../../components/filters/inventory/select-qualification-categorie.vue";
import SelectQualificationLevels from "../../../components/filters/inventory/select-qualification-levels.vue";
import SelectNation from "../../../components/filters/inventory/select-nation.vue";
import SelectRegion from "../../../components/filters/inventory/select-region.vue";
import SelectAcademicDgrees from '../../../components/filters/inventory/select-academic-dgrees.vue';
import CrmInput from '@/components/crm/crm-input';

export default {
    components: {
        selectEmployeeType,
        SelectHigherEducations,
        SelectQualificationCategorie,
        SelectQualificationLevels,
        SelectNation,
        SelectRegion,
        SelectAcademicDgrees,
        CrmInput
    },
    mixins: [doctor, form, drawer],
    data() {
        return {};
    },
    methods: {
        ...mapActions({
            save: "doctors/store",
        }),
        afterOpen(){
            this.form = JSON.parse(JSON.stringify(this.model));
        },
        afterLeave(){
            this.empty();
            this.doctor_informations = [{
                id: 1,
                higher_education_institution_id: null,
                specialization: null,
                when_it_started: "",
                diploma_serial_number: "",
                application_serial_number: "",
					 date_of_give_diploma: '',
                fayls: []
            }];
            this.doctor_work_experiences = [{
                id: 1,
                work_experience: '',
                when_finished: '',
                how_many_years: '',
                qualification_category_id: null,
                received: null,
                when_received: '',
                what_documents: '',
                document_serial_number: '',
                document_date: '',
                name_organization: '',
                person_who_signed: '',
                fayls: [] 
            }];
            this.doctor_certificates = [{
                id: 1,
                name: '',
                which_specialization: null,
                when_received: '',
                what_documents: '',
                certificate_serial_number: '',
                certificate_date: '',
                name_organization: '',
                person_who_signed: '',
                fayls: []
            }];
            this.doctor_academic_degrees = [{
                id: 1,
                what_degree: '',
                where_taken: '',
                documents_signed: '',
                academic_degree_serial_number: '',
                fayls: []
            }];
            this.doctor_rank = [{
                command_number: '',
                person_who_signed: '',
                person_rank: '',
                command_date: '',
                command_serial_number: '',
                doctor_rank_items: [
                    {
                        in_management: '',
                        organization: '',
                        rank: ''
                    }
                ]
            }];
            this.clinics = [];
            this.$refs.img_upload.clearFiles();
            this.$store.commit('clinics/EMPTY_FILIAL');
        }
    },
};
</script>
<style>
.delet-icon {
    font-size: 30px;
    position: absolute;
    right: -24px;
    top: 35px;
    color: red;
}
</style>